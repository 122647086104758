<template>
  <master-detail
    :cols="cols"
    :customResource="customResource"
    :filterQuery="filterQuery"
    :canDelete="false"
    :canEdit="false"
    :hasNewButton="false"
    :hasYearFilter="true"
    :opts="opts"
  >
    <div class="d-flex">
      <v-autocomplete
        v-model="tipoRelatorioSelecionado"
        prefix="Tipo"
        :aria-label="'tipoRelatorio'"
        placeholder="todos"
        item-text="nomeRelatorio"
        item-value="tipoRelatorio"
        hide-details
        clearable
        dense
        :items="opts.tiposRelatorio"
      ></v-autocomplete>
    </div>
  </master-detail>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    "master-detail": () => import("@/components/master-detail.vue"),
  },
  computed: {
    ...mapGetters(["clientId"]),
    cols: function () {
      return [
        {
          key: "nomeArquivo",
          name: "Nome do arquivo",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "tipoRelatorio",
          name: "Tipo de relatório",
          type: this.$fieldTypes.TEXT,
          rel: { to: 'tiposRelatorio', key: 'tipoRelatorio', name: 'nomeRelatorio' },
        },
        {
          key: "user",
          name: "Usuário",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "modificado_em",
          name: "Emitido",
          type: this.$fieldTypes.DATE,
        },
        {
          key: "status",
          name: "Status",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "urlArquivo",
          name: "Baixar",
          type: this.$fieldTypes.DOWNLOAD,
          width: "120px"
        },
      ];
    },
    filterQuery: function () {
      return this.tipoRelatorioSelecionado ? `tipoRelatorio=${this.tipoRelatorioSelecionado}` : '';
    },
    customResource: function () {
      const resource = this.apiResource(`v1/clientes/${this.clientId}/relatoriosExportados`);
      return {
        ...resource,
        get(...args) {
          return resource.get(...args).then((result) => {            
            return result.map((item) => {
              return {
                ...item,
                nomeArquivo: !item.nomeArquivo ? 'Em processamento' : item.nomeArquivo,
              }
            });
          })
        }
      }
    },
    resourceTipoRelatorio: function () {
      return this.apiResource(`v1/clientes/${this.clientId}/tipoRelatorio`);
    },
  },
  created: function () {
    this.resourceTipoRelatorio
      .get()
      .then((response) => (this.opts.tiposRelatorio = response));
  },
  data: function () {
    return {
      tipoRelatorioSelecionado: null,
      opts: {
        tiposRelatorio: [],        
      },
    };
  },
};
</script>
